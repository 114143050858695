import * as schemaItemTypes from './schemaItemTypes';
import { rules } from './validationRules';

/**
 * @type {Array<import('./schema').default>}
 */
export const schema = [
  {
    label: 'label.information',
    type: schemaItemTypes.TITLE,
  },
  {
    type: schemaItemTypes.STRING,
    label: 'label.title',
    prop: 'name',
    rules: [rules.REQUIRED],
  },
  {
    type: schemaItemTypes.STRING,
    label: 'label.short_description',
    prop: 'shortDescription',
    rules: [rules.REQUIRED],
  },
  { type: schemaItemTypes.NUMBER, label: 'label.order', prop: 'order' },
  {
    type: schemaItemTypes.BOOLEAN,
    prop: 'publicAccess',
    label: 'label.public_category',
  },
];

export { schema as default };
