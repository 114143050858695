<template>
  <v-container class="services-modify" fluid>
    <base-dialog
      ref="deleteDialog"
      :title="$t('services.delete_category_dialog_title')"
      :text="$t('services.delete_category_dialog_title')"
    />

    <BaseLoader v-if="isLoading" />
    <template v-else>
      <entity-modify-header class="mb-10" :title="title">
        <div v-if="isEdit" class="ml-2">
          <icon-button v-if="media.isMobile" @click="deleteCategory">
            <v-icon>
              mdi-delete
            </v-icon>
          </icon-button>
          <v-btn
            v-else
            color="secondary"
            class="primary--text services-category-modify__delete"
            large
            @click="deleteCategory"
          >
            {{ $t('button.delete') }}
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </div>
      </entity-modify-header>

      <form-builder :schema="$options.schema" :initial-data="initialData" @submit="submit">
        <template #footer="{ valid }">
          <v-row class="mt-10">
            <v-col>
              <v-btn color="secondary" block class="primary--text" :to="backRoute">{{ $t('button.back') }}</v-btn>
            </v-col>
            <v-col>
              <v-btn type="submit" color="primary" block :disabled="!valid" :loading="isUpload">
                {{ submitButtonText }}
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </form-builder>
    </template>
  </v-container>
</template>

<script>
// Models
import { schema } from '@/schemas/serviceCategory.schema';

// Utils
import { createModelData } from '@/schemas/createModelData';

// Components
import FormBuilder from '@/components/schema/FormBuilder.vue';
import EntityModifyHeader from '@/components/EntityModifyHeader.vue';
import BaseLoader from '@/components/BaseLoader.vue';
import BaseDialog from '@/components/BaseDialog.vue';
import IconButton from '@/components/IconButton.vue';

// Constants
import { SERVICES } from '@/constants/routes';

// Services
import servicesService from '@/services/services';
import notificationService from '@/services/notification';

import analyticsService from '@/services/analytics';
import { CREATE_SERVICE_CATEGORY, EDIT_SERVICE_CATEGORY } from '@/constants/analyticsActions';

export default {
  name: 'ServiceModify',

  components: { FormBuilder, EntityModifyHeader, BaseLoader, BaseDialog, IconButton },

  inject: ['media'],

  props: {
    isEdit: { type: Boolean, default: false },
    id: { type: Number, default: null },
    prevPage: { type: Number, default: 1 },
  },

  data() {
    return {
      isLoading: false,
      initialData: createModelData(schema),
      isUpload: false,
    };
  },

  computed: {
    title() {
      return this.isEdit ? this.$t('services.edit_category') : this.$t('services.add_category');
    },
    submitButtonText() {
      return this.isEdit ? this.$t('button.edit_category') : this.$t('button.add_category');
    },
    backRoute() {
      return { name: SERVICES, query: { page: this.prevPage } };
    },
  },

  mounted() {
    if (this.isEdit) {
      this.initData();
    }
  },

  methods: {
    async initData() {
      this.isLoading = true;
      try {
        const category = await servicesService.getServiceCategoryById(this.id);
        this.initialData = {
          ...category,
        };
      } finally {
        this.isLoading = false;
      }
    },
    async submit(data) {
      if (this.isUpload) {
        return;
      }
      if (this.$options.notificationItem) {
        notificationService.remove(this.$options.notificationItem);
      }
      const bodyRequest = {
        name: data.name,
        shortDescription: data.shortDescription,
        order: data.order,
        publicAccess: data.publicAccess,
      };
      this.isUpload = true;

      const saveService = this.isEdit
        ? servicesService.updateServiceCategoryById
        : servicesService.createServiceCategory;
      try {
        await saveService({ ...bodyRequest, categoryId: this.id });
        analyticsService.track(this.isEdit ? EDIT_SERVICE_CATEGORY : CREATE_SERVICE_CATEGORY);
        notificationService.success(
          this.isEdit ? this.$t('services.categoty_edited') : this.$t('services.category_created'),
          2000
        );
        this.$router.push(this.backRoute);
      } catch (error) {
        this.$options.notificationItem = notificationService.error(
          `${this.$t('error.found_errors')} ${Object.keys(error?.response?.data).join(', ')}`
        );
      } finally {
        this.isUpload = false;
      }
    },
    async deleteCategory() {
      if (this.isUpload) {
        return;
      }

      const needDelete = await this.$refs.deleteDialog.open();

      if (needDelete) {
        this.isUpload = true;

        try {
          await servicesService.deleteCategory(this.id);
          notificationService.success(this.$t('services.categoty_deleted'), 2000);
          this.$router.push(this.backRoute);
        } catch (error) {
          this.$options.notificationItem = notificationService.error(`Error`);
        } finally {
          this.isUpload = false;
        }
      }
    },
  },

  notificationItem: null,
  schema,
};
</script>

<style lang="scss">
.services-modify {
  max-width: 548px !important;
}
</style>
